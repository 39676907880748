
@font-face {
  font-family: 'lazer84regular';
  src: url('lazer84-webfont.woff2') format('woff2'),
       url('lazer84-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

 @font-face {
  font-family: 'basic_lazerregular';
  src: url('basiclazer-webfont.woff2') format('woff2'),
       url('basiclazer-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'summer85regular';
  src: url('summer85-webfont.woff2') format('woff2'),
       url('summer85-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
} 
.App-header {
  margin-top: 25px;
}
.App {
  background-color: ghostwhite;
  color: black;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-family: Courier,Verdana, Tahoma, sans-serif;
  font-weight: bold;
  font-size: 12pt;
}
a:link {
  color: darkred;
}
a:visited {
  color: red;
}
h1 {
  font-weight: bold;
}
ul {
  padding: 0em;
  margin-block-start: 0em;
  margin-block-end: 0em;
  list-style-type: none;
}
.titleText {
  display:flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color:black;
  margin-top: 25px;
  margin-bottom: 25px;
  font-weight: bolder;
}
.titleBar {
  font-size: 2em;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.titleName {
  padding-right: 10px;
  padding-top:10px;
  padding-bottom: 10px;
  color: black;
  font-family: 'lazer84regular';
  display: flex;
  justify-content: center;

}
.titleJob {
  padding-right: 10px;
  padding-top:10px;
  padding-bottom: 10px;
  color: black;
  font-family: 'lazer84regular';
}
.titleUmbrella {
  display: flex;
  justify-content: center;
}
.aboutContent {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: 30%;
  background-color: ghostwhite;
  flex-direction: column;
  border-style: solid;
  border-width: 3px;
  border-color: dimgray;
}
img.personalPic {
  display:flex;
  justify-content: center;
  align-items: center;
  height: 250px;
  width: 250px; 
  margin: 10px;
}
.picContainter {
  display:flex;
  justify-content: center;
}
.picList {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.picListMiddleRes {
  display:none;
}
.aboutText {
  padding: 50px;
  justify-content: center;
  flex-direction: row;
}
.quoteImage {
  height: 330px;
  width: 100%;
  background-color:grey;
  color: ghostwhite;
  margin-top: 20px;
  margin-bottom: 20px;
  background-image: url("../src/components/imageQuote1.png");
  background-repeat: no-repeat;
  opacity: 0.6;
  object-fit: cover;
  display: flex;
  flex-direction: column; 
  justify-content: center;
  align-items: center;
  padding: 50px;
  
}
.linkContent {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: 30%;
  flex-direction: column;
}
.linkContent ul li {
  display: inline;
}
.linkContent img {
  border-style: solid;
  border-width: 3px;
  border-color: dimgrey;
}
.linkList {
  display:flex;
  list-style-type: none;
  justify-content: center;
  align-items: center;
  margin-block-start: 0em;
  margin-block-end: 0em;
  flex-direction: column;
}
.linkListMiddleRes {
  display: none;
  list-style-type: none;
  justify-content: center;
  align-items: center;
  margin-block-start: 0em;
  margin-block-end: 0em;
  flex-direction: row;
}
.linkListMiddleRes img {
  margin: 10px;
}
.resumeContent {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: 30%;
  border-style: solid;
  border-width: 3px;
  border-color: #0F1108;
  width: 306px;
  height: 406px;
}
.picLine {
  display: inline-block;
}
.footerContent {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: black;
  flex-direction: column;
  margin-top: 50px;
}
.navDeco {
  display:flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.navDecom {
  display:flex;
  flex-direction: column;
}
.navBarDeco1 {
   height:10px;
   width: 100vw;
   background-color: dimgrey;
   
}
.navBarDeco2 {
  height:10px;
  width: 100vw;
  background-color: black;
}
.navBarDeco3 {
  height:10px;
  width: 100vw;
  background-color: lightgrey;
}
.navBarDeco1m {
  height:10px;
  width: 100%;
  background-color: dimgrey;
  
}
.navBarDeco2m {
 height:10px;
 width: 100%;
 background-color: black;
}
.navBarDeco3m {
 height:10px;
 width: 100%;
 background-color: lightgrey;
}
.brand {
  display:flex;
  flex-direction: column;
}
.contactList li {
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 15px;
}
.resume {
   width: 550px;
   height: 800px;
   border-style: solid;
   border-width: 3px;
   border-color:black;
}
.contactList {
  list-style-type: none;
}
.fab, .fas {
  margin-right: 10px;
}
.umbrella {
  width: 45px;
  height: 45px;
  margin-left: 20px;
  margin-right: 20px;
  -webkit-animation-name: umb-animation;
  -webkit-animation-duration: 4s;
  animation-name: umb-animation;
  animation-duration: 10s;
  transform: rotate(-20deg);
}
.picDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.picDiv img {
  display: flex;
  justify-content: center;
  align-items: center;
}
@keyframes umb-animation {
  90% {
    transform: rotate(-360deg);
  }
}
@media only screen and (max-width: 1080px) {
  .titleBar {
    font-size: 18px;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .titleTitle {
    align-items: center;
  }
  .aboutText {
    font-size: 10pt;
  }
  .resume {
    display: none;
  }
  img.personalPic {
    display:flex;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (max-width: 1430px) and (min-width: 768px) {
  .aboutContent {
    width:488px
  }
  .picList {
    display: none;
   }
   .picListMiddleRes {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }
  .aboutContent {
    width: 75%;
  }
  .bodhi {
    display: hidden;
  }
}


@media only screen and (min-width: 1430px) {
  .aboutContent{
    width: 75%;
  }
  .linkList {
    flex-direction: row;
  }
  .linkListMiddleRes {
    display: none;
  }
  .picList {
    flex-direction: row;
  }
.linkContent {
   display: inline;
   margin-left: 25px;
   margin-right: 25px;
 }
 .contactList li {
   display: inline;
   padding-bottom: 25px;
 }
 .picDiv {
   margin: 10px;
 }
 .textDiv {
  margin: 10px;
}
 .contactList {
   margin-bottom: 50px;
 }
 .linkList li {
   display: inline;
   padding: 10px;
 }
 .picListMiddleRes {
   display:none;
 }
}
@media only screen and (max-width: 400px) {
  .titleJob {
    display:none;
  }
  img.personalpic {
    height: 200px;
    width: 200px;
  }
}